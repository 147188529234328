import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/about",
		name: "About",
		component: () => import("../views/About.vue"),
	},
	// {
	// 	path: "/facts",
	// 	name: "Facts",
	// 	component: () => import("../views/Facts.vue"),
	// },
	{
		path: "/registration",
		name: "Registration",
		component: () => import("../views/Registration.vue"),
	},
	{
		path: "/registration-cs",
		name: "Registration - Computer Science",
		component: () => import("../views/Registration-ComSci.vue"),
	},
	{
		path: "/team",
		name: "Team",
		component: () => import("../views/Team.vue"),
	},
	{
		path: "/impressum",
		name: "Impressum",
		component: () => import("../views/Impressum.vue"),
	},
	{
		path: "/datenschutzerklaerung",
		name: "Datenschutz",
		component: () => import("../views/Datenschutz.vue"),
	},
];

const router = new VueRouter({
	routes,
});

export default router;
